@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Supprimez les bordures seulement lors du focus */
[type='text']:focus,
input:where(:not([type])):focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Supprime le contour bleu */
  box-shadow: none;
  /* Supprime l'effet de halo */
  border-color: transparent;
  /* Rend la bordure invisible lors du focus */
}


@layer utilities {
  .scrollbar-thin::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #A0AEC0;
    border-radius: 9999px;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: #718096;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbar-thin {
    scrollbar-width: thin;
    scrollbar-color: #A0AEC0 transparent;
  }
}